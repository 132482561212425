'use client';

import { useForm, ValidationError } from '@formspree/react';
import { Dictionary } from '@ts/dictionary';
import Input from '@ui/components/inputs/Input';
import { Button } from '@ngg/components';
import Textarea from '@ui/components/inputs/Textarea';
import { Heading } from '@ui/components/Heading/Heading';
import Paragraph from '@ui/components/Paragraph/Paragraph';

export default function PdpContactForm({
  dictionary,
  reference,
}: {
  reference?: string | null | undefined;
  dictionary: Dictionary;
}) {
  const [state, handleSubmit] = useForm(reference || '');

  const isInputError = (inputName: string) =>
    // @ts-ignore
    state?.errors?.some((error) => error.field === inputName);

  if (state.succeeded) {
    return (
      <div className="md:w-[440px] w-full bg-main p-4">
        <Heading className="text-lg">{dictionary?.formSuccessHeading}</Heading>
        <Paragraph className="mt-4 text-sm">
          {dictionary?.formSuccessText}
        </Paragraph>
      </div>
    );
  }

  return (
    <div className="w-full bg-main">
      <form onSubmit={handleSubmit} className="not-prose max-w-4xl space-y-3">
        <Input
          id="email"
          name="Email"
          type="email"
          required
          fullWidth
          label={dictionary?.placeOrderFormEmailLabel}
          classNames={{
            input: isInputError('Email') ? 'border border-error' : '',
          }}
        />
        <ValidationError
          className="mt-2 text-sm"
          field="Email"
          errors={state.errors}
        />

        <Input
          id="phonenumber"
          name="Phonenumber"
          type="text"
          required
          fullWidth
          label={dictionary?.placeOrderFormPhoneLabel}
          classNames={{
            input: isInputError('Phonenumber') ? 'border border-error' : '',
          }}
        />
        <ValidationError
          className="mt-2 text-sm"
          field="Phonenumber"
          errors={state.errors}
        />

        <Textarea
          name="Message"
          id="message"
          required
          label={dictionary?.placeOrderFormMessageLabel}
          fullWidth
          resizable="none"
          classNames={{
            textarea: isInputError('Message') ? 'border border-error' : '',
          }}
        />
        <ValidationError
          className="mt-2 text-sm"
          field="Message"
          errors={state.errors}
        />

        <Button
          type="submit"
          disabled={state.submitting}
          fullWidth
          className="btn-primary disabled:border-gray-150 disabled:bg-grey-150 disabled:text-grey-300 h-10 w-full grow bg-black text-sm text-white disabled:opacity-100">
          {dictionary?.placeOrderFormSubmitButtonLabel}
        </Button>

        {/* Honeypot */}
        <input type="text" name="_gotcha" className="hidden" />
      </form>
    </div>
  );
}
